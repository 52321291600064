.Header-module__1spwEa__header {
  z-index: 13;
  width: 100%;
  max-width: 1280px;
  min-height: 68px;
  margin: 0 auto 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.Header-module__1spwEa__header__top {
  background-color: var(--bg-block);
  border-bottom: 1px solid var(--line-line);
  z-index: 2;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.Header-module__1spwEa__header__menu-icon {
  display: none;
}

.Header-module__1spwEa__header__tribuna-logo {
  align-items: center;
  gap: 40px;
  max-width: 190px;
  height: 30px;
  margin-top: 20px;
  margin-right: 115px;
  display: flex;
}

.Header-module__1spwEa__header__tribuna-logo__logo {
  align-items: flex-end;
  height: 26px;
  display: flex;
}

.Header-module__1spwEa__header__tribuna-logo__icon {
  margin-right: 5px;
}

.Header-module__1spwEa__header__tribuna-logo__text {
  fill: var(--icons-primary);
}

.Header-module__1spwEa__header__tribuna-logo__seo {
  visibility: hidden;
  position: absolute;
}

.Header-module__1spwEa__header__top-sections {
  flex: 1;
  align-items: center;
  height: 68px;
  display: flex;
  position: relative;
}

.Header-module__1spwEa__header__menu-bonus {
  align-items: center;
  display: flex;
}

.Header-module__1spwEa__header__empty-line {
  display: none;
}

.Header-module__1spwEa__header__dropdown-wrapper, .Header-module__1spwEa__header__right-buttons-wrapper, .Header-module__1spwEa__header__theme-button-wrapper {
  align-items: center;
  display: flex;
}

.Header-module__1spwEa__header__login-button-wrapper {
  cursor: pointer;
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.Header-module__1spwEa__header__bottom {
  background-color: var(--bg-block);
  z-index: 1;
  will-change: margin-bottom;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  min-height: 48px;
  margin-bottom: 0;
  padding: 0 20px;
  transition: top .5s, margin-bottom .5s;
  display: flex;
  position: relative;
  top: 0;
}

.Header-module__1spwEa__header__bottom--scroll-down {
  margin-bottom: -48px;
  top: -48px;
}

.Header-module__1spwEa__header__bottom-item {
  white-space: nowrap;
  height: 48px;
  color: var(--text-secondary);
  align-items: center;
  display: flex;
}

.Header-module__1spwEa__header__bottom-item-wrapper {
  position: relative;
}

.Header-module__1spwEa__header__bottom-item-wrapper:hover .Header-module__1spwEa__header__bottom-item-dropdown {
  display: flex;
}

.Header-module__1spwEa__header__bottom-item-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

@media (min-width: 1025px) {
  .Header-module__1spwEa__header__bottom-item:hover {
    color: var(--text-link-active);
  }
}

@media (max-width: 1024px) {
  .Header-module__1spwEa__header {
    border-bottom: 1px solid var(--line-line);
    min-height: 68px;
  }

  .Header-module__1spwEa__header__top {
    border: none;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 15px 0;
  }

  .Header-module__1spwEa__header__menu-icon {
    order: 2;
    margin: 5px 0 20px;
    display: block;
  }

  .Header-module__1spwEa__header__menu-icon svg {
    fill: var(--icons-primary);
  }

  .Header-module__1spwEa__header__tribuna-logo {
    order: 0;
    justify-content: flex-start;
    margin: 0 0 20px;
    display: flex;
  }

  .Header-module__1spwEa__header__tribuna-logo__logo {
    align-items: center;
  }

  .Header-module__1spwEa__header__menu-bonus {
    order: 1;
    margin-bottom: 20px;
  }

  .Header-module__1spwEa__header__top-sections {
    border-top: 1px solid var(--line-line);
    z-index: 1;
    flex-direction: column;
    order: 6;
    align-items: flex-start;
    gap: 0;
    width: 100%;
    min-width: 100%;
    height: auto;
  }

  .Header-module__1spwEa__header__top-sections--closed {
    display: none;
  }

  .Header-module__1spwEa__header__right-buttons-wrapper {
    order: 5;
  }

  .Header-module__1spwEa__header__right-buttons-wrapper--closed {
    display: none;
  }

  .Header-module__1spwEa__header__login-button-wrapper {
    order: 5;
    align-items: flex-start;
    margin: 20px 0 20px 20px;
  }

  .Header-module__1spwEa__header__login-button-wrapper--closed {
    display: none;
  }

  .Header-module__1spwEa__header__theme-button-wrapper {
    order: 4;
    align-items: flex-start;
    margin: 20px 0;
  }

  .Header-module__1spwEa__header__theme-button-wrapper--closed {
    display: none;
  }

  .Header-module__1spwEa__header__theme-button-wrapper div[data-test="theme-selector"] {
    margin: 0;
  }

  .Header-module__1spwEa__header__empty-line {
    background-color: var(--line-line);
    order: 3;
    width: 100%;
    height: 1px;
    display: block;
  }

  .Header-module__1spwEa__header__empty-line--closed {
    display: none;
  }

  .Header-module__1spwEa__header__dropdown-wrapper {
    order: 5;
    align-items: flex-start;
    min-width: 60px;
    max-width: 60px;
    margin: 20px 0;
  }

  .Header-module__1spwEa__header__dropdown-wrapper--closed {
    display: none;
  }

  .Header-module__1spwEa__header__bottom {
    gap: 0;
    align-items: inherit;
    border: none;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  .Header-module__1spwEa__header__bottom--closed {
    display: none;
  }

  .Header-module__1spwEa__header__bottom-item {
    border-bottom: 1px solid var(--line-line);
    width: 100%;
    height: auto;
    padding: 15px;
    display: block;
  }

  .Header-module__1spwEa__header__bottom-item-wrapper:hover .Header-module__1spwEa__header__bottom-item-dropdown {
    display: none;
  }
}

